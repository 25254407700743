@import 'styles/includes.scss';

.StreamField {
    &__Component--CardListBlock + &__Component--CardListBlock {
        .CardList {
            margin-bottom: 0;

            &--100 {
                margin-top: 0;
                margin-bottom: 0;

                & .Card {
                    margin-bottom: 0;
                }
            }

            &--25 {
                margin-top: 0;
                margin-bottom: 0;

                @include media(m) {
                    margin-top: 9px;
                }
            }

            &--50 {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .CardList__Inner {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__Component--CardListBlock ~ &__Component--CardListBlock {
        .CardList--100 {
            margin-bottom: 0;

            .Card {
                margin-bottom: 0;
            }
        }
    }

    &__Component--CardListBlock {
        .CardList--100 {
            /* margin-bottom: 0; */

            .Card {
                /* margin-bottom: 0; */
            }
        }
    }

    &__Component--CardListBlock:last-of-type {
        margin-bottom: 32px;
    }
}
