@import 'styles/includes.scss';

.HeroFrontPage {
    // background: $colorBlue50;
    margin-bottom: 30px;

    @include media(m) {
        margin-bottom: 56px;
        background: $colorBlue50;
    }

    &__MobileContainer {
        display: block;
        background-image: url('#{$assetPath}img/city.png');
        background-size: 150%;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: right;
        padding: 17.2%;
        background-color: $colorBlue50;

        @include media(m) {
            display: none;
        }
    }

    &__Container {
        @include media(m) {
            padding: 100px 0;
            background-image: url('#{$assetPath}img/city.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position-y: bottom;
            background-position-x: right;
            max-width: 1900px;
            margin: 0 auto;

            &:before {
                display: none;
            }
        }
    }

    &__ContainerInner {
        @extend %container;
        padding-top: 24px;
        padding-bottom: 41px;
    }

    &__Title {
        @extend %h1;
        max-width: 740px;
        margin-bottom: 21px;

        @include media(xl) {
            margin-bottom: 31px;
        }
    }

    &__Text {
        @extend %body-copy;
        max-width: 600px;
    }
}
