@import 'styles/includes.scss';

.Notice {
    $root: &;

    @extend %container;
    background: #fff;
    display: block;
    padding: 14px 82px 14px 33px;
    position: relative;
    color: $colorBlack;
    text-decoration: none;
    min-height: 92px;
    margin-top: 1px;
    margin-bottom: 1px;

    @include media(s) {
        margin-top: 14px;
        margin-bottom: 14px;
    }

    &--NewsPage {
        @extend %container-text;
        padding: 14px 82px 14px 33px;
    }

    @include media(m) {
        padding: 19px 43px;
    }

    &:before {
        content: '';
        display: block;
        background-color: $colorBeige50;
        width: 15px;
        min-height: 92px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        @include media(m) {
            width: 92px;
            background-image: url('#{$assetPath}svg/clock.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50px;
        }
    }

    &:after {
        content: '';
        display: inline-block;
        width: 11px;
        height: 18px;
        background-image: url('#{$assetPath}svg/arrow--right-blue.svg');
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
        position: absolute;
        right: 34px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;

        @include media(m) {
            display: none;
        }
    }

    &__Label {
        @extend %links-small;
        display: none;

        @include media(m) {
            text-transform: uppercase;
            color: $colorBeige900;
            letter-spacing: 0.4px;
            display: block;
            padding-bottom: 6px;
            margin-left: 92px;
        }
    }

    &__Text {
        @extend %notices;
        display: block;

        &:before {
            display: block;
            content: '';
            width: 30px;
            height: 30px;
            background-image: url('#{$assetPath}svg/clock.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-left: -5px;
            margin-bottom: 6px;
        }

        @include media(m) {
            margin-left: 92px;

            &:before {
                display: none;
            }
        }
    }
}
